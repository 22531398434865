<template>
    <div class="d-flex flex-wrap align-items-center justify-content-between mb-2">
    <div class="col-md-6 col-12 mb-md-0 mb-3 ps-3">
            <a title="Edit" v-show="statusEdit" class="btn btn-success me-2 rounded-pill" @click="showEdit()"><span class="fa fa-pencil"></span> {{buttonName}}</a>
            <a title="Refresh" v-show="statusRefresh" class="btn btn-success me-2 rounded-circle" @click="showRefresh()"><i class="fa fa-refresh"></i></a>
    </div>
    <div v-show="statusFilter" class="position-relative col-md-4 col-12">
        <input v-on:keyup.enter="prosesFilter()" type="text" class="form-control" minlength="5" placeholder="Search ..." style="margin-top: 1px;" v-model="pages.search">
        <button @click="prosesFilter()" type="button" class="bg-transparent btn" style="position: absolute; top: 3px; right: 10px;"><i class="fa fa-search"></i></button>
    </div>
</div>
</template>
<script>
/* eslint-disable */
import download from '../assets/js/download.js';

export default {
components:{

},
data () {
    return {
        pages : {search:''},
    };
},
props:{
    clickAdd: {
        type: Function,
        default: () => { }
    },
    buttonName: {
      type: String,
      required: true,
    },
    clickEdit: {
        type: Function,
        default: () => { }
    },
    clickDelete: {
        type: Function,
        default: () => { }
    },
    clickRefresh: {
        type: Function,
        default: () => { }
    },
    clickPrint: {
        type: Function,
        default: () => { }
    },
    setFilter: {
        type: Function,
        default: () => {}
    },
    statusAdd : {
        type : Boolean,
        default : true
    },
    statusEdit : {
        type : Boolean,
        default : true
    },
    statusDelete : {
        type : Boolean,
        default : true
    },
    statusRefresh : {
        type : Boolean,
        default : true
    },
    statusExport:{
        type : Boolean,
        default : true
    },
    statusPrint:{
        type : Boolean,
        default : true
    },
    statusFilter : {
        type : Boolean,
        default : true
    },
    // Json to download
    'data':{
        type: Array,
        required: true
    },
    'fields':{
        type: Object,
        required: false
    },
    'exportFields':{
        type: Object,
        required: false
    },
    'title':{
        default: null
    },
    'footer':{
        default: null
    },
    'name':{
        type: String,
        default: "data.xls"
    },
    'meta':{
        type: Array,
        default: () => []
    }
},
computed:{
    downloadFields: function(){
        if(this.fields !== undefined)
            return this.fields
        if(this.exportFields !== undefined)
            return this.exportFields
    }
},
methods:{
    showAdd (){
        this.clickAdd();
    },
    prosesFilter(){
        this.setFilter(this.pages.search);
    },
    showRefresh (){
        this.pages.search = "";
        this.clickRefresh();
    },
    showPrint(){
        this.clickPrint();
    },
    showEdit(){
        this.clickEdit();
    },
    showDelete(){
        this.clickDelete();
    },
    /**Untuk excel*/
    generate() {
        if(!this.data.length){
            return
        }
        let json = this.getProcessedJson(this.data, this.downloadFields)
        if(this.type == 'csv'){
            return this.export(this.jsonToCSV(json), this.name, "application/csv");
        }
        return this.export(this.jsonToXLS(json), this.name, "application/vnd.ms-excel");
    },
    /*
    Use downloadjs to generate the download link
    */
    export (data, filename, mime) {
        let blob = this.base64ToBlob(data, mime)
        download(blob, filename, mime)
    },
    /*
    jsonToXLS
    ---------------
    Transform json data into an xml document with MS Excel format, sadly
    this format show a prompt when open due to a default behavior
    on Microsoft office. It's recommended to use CSV format instead.
    */
    jsonToXLS (data) {
        let xlsTemp = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>${table}</table></body></html>'
        let xlsData = '<thead><tr>'
        const colspan = Object.keys(data[0]).length
        //Header
        if( this.title != null ){
            xlsData += this.parseExtraData(this.title, '<th colspan="'+colspan+'">${data}<th></tr><tr>')
        }
        //Fields
        for (let key in data[0]) {
            xlsData += '<th>' + key + '</th>'
        }
        xlsData += '</tr></thead>'
        xlsData += '<tbody>'
        //Data
        data.map(function (item) {
            xlsData += '<tbody><tr>'
            for (let key in item) {
                xlsData += '<td>' + item[key] + '</td>'
            }
            xlsData += '</tr></tbody>'
        })
        //Footer
        if( this.footer != null ){
            xlsData += '<tfooter><tr>'
            xlsData += this.parseExtraData(this.footer, '<td colspan="'+colspan+'">${data}<td></tr><tr>')
            xlsData += '</tr></tfooter>'
        }
        return xlsTemp.replace('${table}', xlsData)
    },
    /*
    jsonToCSV
    ---------------
    Transform json data into an CSV file.
    */
    jsonToCSV (data) {
        var csvData = [];
        //Header
        if( this.title != null ){
            csvData.push(this.parseExtraData(this.title, '${data}\r\n'));
        }
        //Fields
        for (let key in data[0]) {
            csvData.push(key);
            csvData.push(',');
        }
        csvData.pop();
        csvData.push('\r\n');
        //Data
        data.map(function (item) {
            for (let key in item) {
                let escapedCSV = item[key] + '' // cast Numbers to string
                if (escapedCSV.match(/[,"\n]/)) {
                    /* eslint-disable-next-line */
                    escapedCSV = '"' + escapedCSV.replace(/\"/g, "\"\"") + '"'
                }
                csvData.push(escapedCSV);
                csvData.push(',');
            }
            csvData.pop();
            csvData.push('\r\n');
        })
        //Footer
        if( this.footer != null ){
            csvData.push(this.parseExtraData(this.footer, '${data}\r\n'));
        }
        return csvData.join('');
    },
    /*
    getProcessedJson
    ---------------
    Get only the data to export, if no fields are set return all the data
    */
    getProcessedJson: function(data, header){
        let keys = this.getKeys(data, header)
        let newData = []
        let _self = this
        data.map(function (item) {

            let newItem = {}
            for( let label in keys){
                let property = keys[label]
                newItem[label] = _self.getNestedData(property, item)
            }
            newData.push(newItem)
        })
        return newData
    },
    getKeys: function(data, header){
        if( header ){
            return header
        }
        let keys = {}
        for (let key in data[0]) {
            keys[key] = key
        }
        return keys
    },
    /*
    parseExtraData
    ---------------
    Parse title and footer attribute to the csv format
    */
    parseExtraData(extraData, format){
        let parseData = ''
        if( Array.isArray(extraData) ){
            for (var i = 0; i < extraData.length; i++) {
                parseData += format.replace('${data}', extraData[i])
            }
        }
        else{
            parseData += format.replace('${data}', extraData)
        }
        return parseData
    },
    callItemCallback: function(field, itemValue) {
        if (typeof field === 'object' && typeof field.callback === 'function') {
            return field.callback(itemValue);
        }
        return itemValue;
    },
    getNestedData: function(key, item) {
        const field = (typeof key === 'object') ? key.field : key;

        let valueFromNestedKey = null
        let keyNestedSplit = field.split(".")

        valueFromNestedKey = item[keyNestedSplit[0]]
        for (let j = 1; j < keyNestedSplit.length; j++) {
            valueFromNestedKey = valueFromNestedKey[keyNestedSplit[j]]
        }

        valueFromNestedKey = this.callItemCallback(key, valueFromNestedKey);

        return valueFromNestedKey;
    },
    base64ToBlob (data, mime) {
        let base64 = window.btoa(window.unescape(encodeURIComponent(data)))
        let bstr   = atob(base64)
        let n      = bstr.length
        let u8arr  = new Uint8ClampedArray(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
    },
}
}
</script>